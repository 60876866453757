import 'magnific-popup';
import 'slick-carousel';
import moment from 'moment';

jQuery( document ).ready(function($) {
	
	//Carousels
	$('.music-carousel').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		
		prevArrow:"<img class='prev slick-prev' src='./dist/img/arrow.svg' alt='next'>",
		nextArrow:"<img class='next slick-next' src='./dist/img/arrow.svg' alt='next'>",
		responsive:[
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					centerMode:true,
					centerPadding:'50px',
				},
			},
			{
				breakpoint: 400,
				settings: {
					centerMode:true,
					centerPadding:'40px',
					slidesToShow: 1,
				},
			},
		]
	});
	$('.video-carousel').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow:"<img class='next slick-next' src='./dist/img/arrow.svg' alt='next'>",
		responsive:[
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					centerMode:true,
					centerPadding:'50px',
					slidesToShow: 1,
				},
			},
		]
	});
	$('.newsletter').on('submit', function(e) {
		e.preventDefault();
		const DATA = $(this).serialize();
		$.ajax({
			type: 'POST',
			url: $(this).attr('action'),
			dataType: 'json',
			data: DATA,
			xhrFields: {
				withCredentials: false,
			},
			success: function (data) {
				$('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
			},
			error: function (err) {
				console.log(err);
			}
		});
	});

	$('.presave-signup').on('submit', function(e) {
		e.preventDefault();
		const DATA = $(this).serialize();
		$.ajax({
			type: 'POST',
			url: $(this).attr('action'),
			dataType: 'json',
			data: DATA,
			xhrFields: {
				withCredentials: false,
			},
			success: function (data) {
				$('.inputs-wrap-signup').html('<p class="newsletter-thanks">Your submission has been received!</p>');
			},
			error: function (err) {
				console.log(err);
			}
		});
	});

	$('nav .internal').on('click', function(e){
		e.preventDefault();
		let target = $(this).attr('href')
		if (target == '#home'){
			$('#main section').hide();
			$('#main section').removeClass('active');
			$('#hero').fadeIn();
			$('#tour').fadeIn();
			$('#music').fadeIn();
			$('#video').fadeIn();
			$('#cta').fadeIn();
			$('.video-carousel').slick('unslick');
			$('.video-carousel').slick({
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				nextArrow:"<img class='next slick-next' src='./dist/img/arrow.svg' alt='next'>",
				responsive:[
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 600,
						settings: {
							centerMode:true,
							centerPadding:'50px',
							slidesToShow: 1,
						},
					},
				]
			});
			$('.video-carousel .item').on('mouseenter', function(e){
				e.preventDefault();
				$(this).find('video')[0].play();
			})
			$('.video-carousel .item').on('mouseleave', function(e){
				e.preventDefault();
				$(this).find('video')[0].pause();
			})
		} else {
			$('#main section').hide();
			$('#main section').removeClass('active');
			$(target).addClass('active');
			$(target).fadeIn();
			$('.video-carousel').slick('unslick');
			$('.video-carousel').slick({
				infinite: true,
				slidesToShow: 5,
				rows: 2,
				slidesToScroll: 1,
				nextArrow:"<img class='next slick-next' src='./dist/img/arrow.svg' alt='next'>",
				responsive:[
					{
						breakpoint: 1100,
						settings: {
							rows: 1,
							slidesToShow: 3,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 600,
						settings: {
							rows: 1,
							centerMode:true,
							centerPadding:'50px',
							slidesToShow: 1,
						},
					},
				]
			});
			$('.video-carousel .item').on('mouseenter', function(e){
				e.preventDefault();
				$(this).find('video')[0].play();
			})
			$('.video-carousel .item').on('mouseleave', function(e){
				e.preventDefault();
				$(this).find('video')[0].pause();
			})
		}
	});
	$('.menu-toggle').on('click', function(e){
		e.preventDefault();
		$('.mobile-menu').toggleClass('open');
	});
	$('.mobile-menu nav a').on('click', function(e){
		$('.mobile-menu').removeClass('open');
	})
	$('.video-carousel .item').on('mouseenter', function(e){
		e.preventDefault();
		$(this).find('video')[0].play();
	})
	$('.video-carousel .item').on('mouseleave', function(e){
		e.preventDefault();
		$(this).find('video')[0].pause();
	})

	$('.dropdown-btn').on('click', function(e){
		e.preventDefault();
		$('.dropdown, .dropdown .btn').toggleClass('closed');
	})

    // BIT
    $.ajax( {
		url: 'https://rest.bandsintown.com/artists/p!nk/events?app_id=45PRESS_pink',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			console.log(data);
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					let region = event.venue.region?event.venue.region:event.venue.country
					let location = event.venue.city + ', ' + region;
					if ( location === ', ' ) {
						location = '';
					}
					if ( event.venue.name.toLowerCase() === 'streaming live' ) {
						location = 'Online';
					}
					html += '<div class="event">';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM D YYYY' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					if (event.offers.length) {
						for ( let offer of event.offers ) {
							html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
						}
					} else {
						html += '<a href="' + event.url + '" target="_blank" class="btn btn">More Info</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				html+='<a href="#" class="seealllink btn pink">View More Dates</a>'
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
			$('.seealllink').on('click', function(e){
				e.preventDefault();
				$('#events').addClass('seeall');
			})
		}
	} );
	//presaves
	//Apple Form
	$('body').on('click','.btn-apple', function( e ) { 
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#apple-popup',
				type: 'inline'
			}
		} );
		var sm = new SMEAppleMusic({
			ae: {
				ae: "db1275a443487a733c0a9127248d15de1f9e914d0e58fb57d1e3dbf16b7d6167",
				brand_id: "3443823",
				segment_id: "2024090",
				activities: '{"actions":{"presave":124117},"mailing_list_optins":{"a0S61000001YpzEEAS":124134}}'
			  },
			  am: {
				dev_token: "",
				save_mode: "library",
				custom_playlist_name: "",
				new_apple_presave: true,
				resources: {
				  playlists: [],
				  albums: []
				}
			  },
			  sf: {
				form: "354984",
				default_mailing_list: ""
			  },
			  smf: {
				campaign_id: "354984",
				campaign_key: "ccaf23a445ff74431f9c3c1cc491826f"
			  }
			});
			document.getElementById("apple_music_form").addEventListener("submit", function (e) {
			  e.preventDefault();
			  // Grab the email address value.
			  var email = document.getElementById("apple_music_email_address").value;
			  // Pass the email address and opt in status
			  // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
			  sm.doActions(email, false, ["a0S61000001YpzEEAS"], false, [""]).then(function (res) {
	  
				$.magnificPopup.open( {
					items: {
						src: '#thank-you',
						type: 'inline'
					}
				} );
			}).catch(function(err) {
				console.log(err);
			});
		});
	} );
	//Spotify / Deezer
	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function (callback, form_id) {
		let hash;
		let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for (let i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			this.url_vars.push(hash[0]);
			this.url_vars[hash[0]] = hash[1];
			}
		this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
		this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
		this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
		this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
		this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
		if (typeof callback === 'function') {
			callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
		}
		}
	};
	/* String state
		*  The status of the pre-save.
		*  Int ae_member_id
		*  The Appreciation Engine member ID. This can be passed to the forms processor.
		*  String spotify_user
		*  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
		*  String deezery_user
		*  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
		*/
	SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if (state === 'success' || state === 'thank-you') {
			$.magnificPopup.open( {
				items: {
					src: '#thank-you',
					type: 'inline'
				}
			} );
		}
	});

	$('.mfp-inline').magnificPopup({
		type:'inline'
	});
});